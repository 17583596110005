import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import { useRouter } from 'next/navigation';

const NotFoundPage = () => {
	const router = useRouter();

	const handleGoHome = () => {
		router.push('/');
	};
	return (
		<div className="flex-center min-h-screen w-screen flex-col space-y-14">
			<div className="type-subnav text-primary">
				There is no site connected with this domain.
			</div>
			<SVButton use={SVButtonUSES.DEFAULT} onClick={handleGoHome}>
				Go to home
			</SVButton>
		</div>
	);
};

NotFoundPage.withNavHidden = true;

export default NotFoundPage;
